import React from 'react';
import { getUser, getSessionLoginData,saveMessages_idb,reply_saveMessages_idb } from '../../Utils/Common';
import {getparticipants_details,getparticipants_name} from "../../Utils/CustomFun"
import { instanceMsgSeenAPI, leave_roomAPI, geolocation } from '../../Utils/API';
import dexie_db, {IDB_insert, IDB_update, IDB_get} from "../../Utils/indexedSchema";
const initialState = {
    totalmembers: 0,
    conversation_list: [],
    active_conv: {},
    msg_loader: false,
    root_textBox: React.createRef(),
    user_data: getUser() === null ? { user_id: 'demo' } : getUser(),
    conversation_id: '',
    right_side_loader: true,
    logindata: window.location.pathname === '/adminpanel' ? getSessionLoginData() : {},
    company_login: {},
    error: '',
    msgSelection: false,
    active_thread: null,
    reply_data: [],
    online_users: [],
    online_users_token: {},
    filter_status: false,
    idle_status: false,
    link_msg: [],
    open_my_conv: false,
    change_share_link: {},
    flagged_conv: [],
    archived_conv: [],
    private_conv: [],
    link_conv: [],
    tagged_conv: [],
    change_file_data: {},
    activeOnlyMyConvdata: false,
    activeOnlyOtherConvdata: false,
    pin_convs: [],
    unpin_convs: [],
    global_loader_status: false,
    leave_roomHIT: leave_roomAPI,
    geolocationHIT: geolocation,
    threadedConvData: [],
    threadedDetails: {},
    flagged_conv_data: {},
    task_panel_options: {},
    task_id_active: null,
    DiscussionList: [],
    NotificationList: [],
    maintenanceFileList: [],
    maintenanceActivityList: [],
    taskData: [],
    CostFile: [],
    files_hub: [],
    tags_hub: [],
    pinnedTag: "",
    pinnedTagDetails: "",
    company_users:localStorage.getItem('company_users') ? JSON.parse(localStorage.getItem('company_users')):{},
    report_list: [],
    report_total_count: 0,
    report_total_stats: {},
    report_total_update: false,
    report_one_update: ""
}
const deleteRoom = (state, action) => {
    if (state.logindata.unpin_convs !== undefined) {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                unpin_convs: state.logindata.unpin_convs.filter((i) => i.conversation_id !== action.payload.conversation_id),
                pin_convs: state.logindata.pin_convs.filter((i) => i.conversation_id !== action.payload.conversation_id)
            }
        }
    } else {
        return { ...state }
    }

}
const filterLinkSTR = (state, action) => {
    // //console.log(58,state.link_msg)
    return {
        ...state,
        link_msg: state.link_msg.map((msg) => (msg.msg_body !== null && msg.msg_body !== '' && msg.msg_body.toLowerCase().indexOf(action.payload.toLowerCase()) > -1) || (msg.url_base_title !== null && msg.url_base_title !== '' && msg.url_base_title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1) || (msg.url_title !== null && msg.url_title !== '' && msg.url_title.toLowerCase().indexOf(action.payload.toLowerCase()) > -1) || action.payload === '' ? { ...msg,is_show: true } : { ...msg, is_show: false }),
    }
}
const updateOneMsg = (state, action) => {
    //console.log(72,action)
    let retunData = {};
    let data = [];
    let data2 = {};
    let entry = false;
    let url_base_title = false;
    let sort_status = false;
    if (action.payload.field === 'has_delete') {
        data = [state.logindata.user.id];
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            if (action.payload.delete_type === 'for_all') {
                data = [...state.logindata.active_conv.details.participants, 'for_all'];
            }
        }
    } else if (action.payload.field === 'edit_history') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.edit_history;
            url_base_title = true;
            sort_status = true;
            data2 = {created_at:action.payload.msg.created_at}
        }
    } else if (action.payload.field === 'has_emoji') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.has_emoji;
        }
    } else if (action.payload.field === 'has_flagged') {

        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.has_flagged === 'yes' ? [state.logindata.user.id] : [];
        }

    } else if (action.payload.field === 'has_star') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.has_flagged === 'yes' ? [state.logindata.user.id] : [];
        }

    }
    else if (action.payload.field === 'secret_user_name') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = getparticipants_name(action.payload.secret_user);
        }

    } else if (action.payload.field === 'secret_user_details') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = getparticipants_details(action.payload.secret_user);
        }

    } else if (action.payload.field === 'secret_user') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.secret_user;
            sort_status = true;
            data2 = {created_at:action.payload.msg.created_at}
        }

    } else if (action.payload.field === 'is_secret') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = action.payload.is_secret;
        }

    } else if (action.payload.field === 'msg_type') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            data = 'checklist';
        }
    } else if (action.payload.field === 'full_delete') {
        if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
            entry = true;
            state.logindata.active_conv.msgs = state.logindata.active_conv.msgs.filter((msg) => (msg.msg_id !== action.payload.msg_id));
        }
    }
    if (entry) {
        
        if (action.payload.is_reply_msg === 'yes') {
            retunData = {
                ...state,
                link_msg: state.link_msg.map((msg) => msg.msg_id === action.payload.msg_id && url_base_title ? { ...action.payload.msg } : msg),

                reply_data: url_base_title ?
                    state.reply_data.map((msg) =>
                    (msg.msg_id === action.payload.msg_id ?
                        { ...action.payload.msg } : msg
                    )
                    ) :
                    state.reply_data.map((msg) =>
                    (msg.msg_id === action.payload.msg_id ?
                        { ...msg, [action.payload.field]: data, msg_body: action.payload.msg_body !== undefined ? action.payload.msg_body : msg.msg_body, checklist: data === 'checklist' ? action.payload.checklist : msg.checklist } : msg
                    )
                    )
            }

        } else {
            // //console.log(93,url_base_title,action.payload.msg,state.flagged_conv_data);

            retunData = {
                ...state,
                link_msg: state.link_msg.map((msg) => msg.msg_id === action.payload.msg_id && url_base_title === true ? { ...action.payload.msg } : msg),
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        msgs: url_base_title ? state.logindata.active_conv.msgs.map((msg) =>
                        (msg.msg_id === action.payload.msg_id ?
                            { ...action.payload.msg } : msg
                        )
                        ) : state.logindata.active_conv.msgs.map((msg) =>
                        (msg.msg_id === action.payload.msg_id ?
                            { ...msg,...data2, [action.payload.field]: data, msg_body: action.payload.msg_body !== undefined ? action.payload.msg_body : msg.msg_body, checklist: data === 'checklist' ? action.payload.checklist : msg.checklist } : msg
                        )
                        )

                    }
                },

                flagged_conv: action.payload.field === 'has_flagged' ? state.flagged_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, total: action.payload.has_flagged === 'yes' ? (v.total + 1) : (v.total - 1) } : v) : state.flagged_conv,

                // flagged_conv_data: state.flagged_conv_data[action.payload.conversation_id] ? {
                //     ...state.flagged_conv_data,
                //     [action.payload.conversation_id]: state.flagged_conv_data[action.payload.conversation_id].map((msg) =>
                //     (msg.msg_id === action.payload.msg_id ?  {...msg, [action.payload.field]:data} : msg)
                // )
                // }: state.flagged_conv_data,

                active_thread: state.active_thread !== null && state.active_thread.msg_id === action.payload.msg_id ?
                    action.payload.delete_type === 'for_all' ?
                        { ...state.active_thread, has_delete: ['for_all'] } : (action.payload.field === 'secret_user' || action.payload.field === 'secret_user_name' || action.payload.field === 'secret_user_details') ? {
                            ...state.active_thread, [action.payload.field]:
                                data
                        }
                            : state.active_thread : state.active_thread,



            }
        }
    } else {

        retunData = { ...state };
    }
    // for(let i of retunData.logindata.active_conv.msgs){
    //     if(i.msg_id == action.payload.msg_id){
    //         //console.log(213,i)
    //         IDB_insert("message",i);

    //     }
    // }
    // for(let i of retunData.reply_data){
    //     if(i.msg_id == action.payload.msg_id){
    //         reply_saveMessages_idb([i]);

    //     }
    // }
   
    if(sort_status){
       
        retunData =  {...retunData,
            logindata:{
                ...retunData.logindata,
                active_conv:{
                ...retunData.logindata.active_conv,
                msgs:retunData.logindata.active_conv.msgs.sort((a, b) => a.created_at > b.created_at ? 1 : -1)
                }
            }
        }
    }
    return retunData;

}
const updateOneMsgTask = (state, action) => {
    var task_id = state.task_panel_options?.data && state.task_panel_options?.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    if (task_id !== action.payload.task_id) return { ...state }
    var data = [];
    if (action.payload.delete_type === 'for_all') {
        data = 'for_all';
    }else{
        data = action.payload.user_id
    }

    return {
        ...state,
        DiscussionList: [...state.DiscussionList.filter(msg => msg.msg_id !== action.payload.msg_id)]
            
        
    }

    // return {
    //     ...state,
    //     DiscussionList: [...state.DiscussionList.map((msg) =>
    //         (msg.msg_id === action.payload.msg_id ?
    //             { ...msg, "has_delete": [...msg.has_delete, data]} : msg
    //         )
    //         )]
    //         .filter(u => u.conversation_id !== action.payload.conversation_id)
        
    // }
    
}
//function updateMessageStar(state, action) {

// if (action.payload.is_reply_msg === 'yes') {
//     return {
//         ...state,
//         reply_data: 
//             state.reply_data.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v
//             )
//     }
// } else {
// return {
//     ...state,
//     logindata: {
//         ...state.logindata,
//         active_conv: {
//             ...state.logindata.active_conv,
//             msgs: state.logindata.active_conv.msgs.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v)
//         }
//     }
// }

// }

//}


const addNewRoom = (state, action) => {
    if (action.payload.single === 'yes') {
        // //console.log(37,action.payload)
        action.payload['title'] = action.payload.conv_name_img[state.logindata.user.id].title;
        action.payload['conv_img'] = action.payload.conv_name_img[state.logindata.user.id].img;
    }
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                details: {
                    ...state.logindata.active_conv.details,
                    temp_user: action.payload.conversation_id === state.logindata.active_conv.details.conversation_id ? [] : state.logindata.active_conv.details.temp_user
                }
            },
            unpin_convs: [action.payload, ...state.logindata.unpin_convs.filter(u => u.conversation_id !== action.payload.conversation_id)]
        }
    }
}

const setActiveThread = (state, action) => {
    
    return {
        ...state,
        active_thread: action.payload
    }
}
const setReplyData = (state, action) => {
    return {
        ...state,
        reply_data: [...action.payload]
    }
}
const setMuteDataOnProps = (state, action) => {
    if (action.payload.type === 'add' || action.payload.type === 'update') {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    details: {
                        ...state.logindata.active_conv.details,
                        has_mute: (action.payload.active === 'yes' ? [state.logindata.user.id] : state.logindata.active_conv.details.has_mute),
                        mute: (action.payload.active === 'yes' ? [action.payload.data] : state.logindata.active_conv.details.mute)
                    }
                },
                unpin_convs: state.logindata.unpin_convs.map((conv) =>
                (conv.conversation_id === action.payload.conversation_id ?
                    { ...conv, "mute": [action.payload.data], "has_mute": [state.logindata.user.id] } : conv
                )
                ),
                pin_convs: state.logindata.pin_convs.map((conv) =>
                (conv.conversation_id === action.payload.conversation_id ?
                    { ...conv, "mute": [action.payload.data], "has_mute": [state.logindata.user.id] } : conv
                )
                ),
            }
        }
    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    details: {
                        ...state.logindata.active_conv.details,
                        has_mute: (action.payload.active === 'yes' ? [] : state.logindata.active_conv.details.has_mute),
                        mute: (action.payload.active === 'yes' ? [] : state.logindata.active_conv.details.mute)
                    }
                },
                unpin_convs: state.logindata.unpin_convs.map((conv) =>
                (conv.conversation_id === state.logindata.active_conv.details.conversation_id ?
                    { ...conv, "mute": [], "has_mute": [] } : conv
                )
                ),
                pin_convs: state.logindata.pin_convs.map((conv) =>
                (conv.conversation_id === state.logindata.active_conv.details.conversation_id ?
                    { ...conv, "mute": [], "has_mute": [] } : conv
                )
                ),
            }
        }
    }
}
const setOneReplyData = (state, action) => {
    var myid = action.payload.mention_user === null ? [] : action.payload.mention_user.filter((i) => i === state.logindata.user.id);
    // //console.log(260,state.threadedDetails)
    // //console.log(260,state.threadedConvData)
    //console.log((state.logindata.total_unread_reply || 0))
    var stateN = {
        ...state
    }
    let conv = [...state.pin_convs, ...state.unpin_convs].filter(v => v.conversation_id === action.payload.conversation_id)

    if (state.active_thread != null && state.active_thread.msg_id === action.payload.reply_for_msgid) {
        instanceMsgSeenAPI({
            conversation_id: action.payload.conversation_id,
            msg_ids: [action.payload.msg_id],
            is_reply_msg: 'yes',
            root_msg_id: action.payload.reply_for_msgid
        });
        if (state.idle_status) {
            action.payload.msg_status = [state.logindata.user.id];
        }

        let msg_id = state.logindata.active_conv.msgs.filter((msg) => msg.msg_id === action.payload.reply_for_msgid);
        let has_reply_attach = 0;
        if (msg_id.length) {
            if (!msg_id[0].hasOwnProperty('has_reply_attach')) { msg_id[0].has_reply_attach = 0; }
            if (action.payload.all_attachment.length) has_reply_attach = msg_id[0].has_reply_attach + 1
            else has_reply_attach = msg_id[0].has_reply_attach;
        }


        stateN = {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map((msg) =>

                    (msg.msg_id === action.payload.reply_for_msgid ?
                        {
                            ...msg,
                            has_reply: (msg.has_reply + 1),
                            has_reply_attach: has_reply_attach,
                            last_reply_time: action.payload.created_at,
                            last_reply_name: action.payload.sendername
                        } : msg)


                    )

                }
            },
            reply_data: [
                ...state.reply_data,
                action.payload
            ]
        }
    } else if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
        stateN = {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map((msg) =>
                    (msg.msg_id === action.payload.reply_for_msgid ?
                        { ...msg, unread_reply: action.payload.sender !== state.logindata.user.id ? (msg.unread_reply + 1) : msg.unread_reply, has_reply: (msg.has_reply + 1), last_reply_time: action.payload.created_at, last_reply_name: action.payload.sendername } : msg
                    )
                    )

                },
                total_r_mention_user: action.payload.sender !== state.logindata.user.id && action.payload.mention_user.indexOf(state.logindata.user.id) > -1 ? (state.logindata.total_r_mention_user + 1) : state.logindata.total_r_mention_user,
                total_unread_reply: action.payload.sender !== state.logindata.user.id ? ((state.logindata.total_unread_reply || 0) + 1) : (state.logindata.total_unread_reply || 0),
                pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? v.r_mention_user = 1 : v.r_mention_user, urreply: action.payload.sender !== state.logindata.user.id ? ((v.urreply || 0) + 1) : v.urreply } : v),
                unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? v.r_mention_user = 1 : v.r_mention_user, urreply: action.payload.sender !== state.logindata.user.id ? ((v.urreply || 0) + 1) : v.urreply } : v),
            }
        }
    } else {
        stateN = {
            ...state,
            logindata: {
                ...state.logindata,
                total_r_mention_user: action.payload.sender !== state.logindata.user.id && action.payload.mention_user.indexOf(state.logindata.user.id) > -1 ? (state.logindata.total_r_mention_user + 1) : state.logindata.total_r_mention_user,
                total_unread_reply: action.payload.sender !== state.logindata.user.id ? ((state.logindata.total_unread_reply || 0) + 1) : (state.logindata.total_unread_reply || 0),
                pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? v.r_mention_user = 1 : v.r_mention_user, urreply: action.payload.sender !== state.logindata.user.id ? ((v.urreply || 0) + 1) : v.urreply } : v),
                unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? v.r_mention_user = 1 : v.r_mention_user, urreply: action.payload.sender !== state.logindata.user.id ? ((v.urreply || 0) + 1) : v.urreply } : v),

            }
        }
        if (stateN.threadedConvData.indexOf(action.payload.conversation_id) === -1) {
            stateN = {
                ...stateN,
                threadedConvData: [...stateN.threadedConvData, action.payload.conversation_id],
                threadedDetails: {
                    ...stateN.threadedDetails,
                    [action.payload.conversation_id]: {
                        ...conv[0],
                        urreply: (conv && conv[0] && conv[0].urreply ? conv[0].urreply + 1 : 0)
                    }
                }
            }
        }
    }
    //console.log(436,action.payload.reply_for_msgid)
    // for(let i of stateN.logindata.active_conv.msgs){

    //     if(i.msg_id == action.payload.reply_for_msgid){
    //         //console.log(438,i)
    //         IDB_insert("message",i)
    //     }
    // }
    // for(let i of stateN.reply_data){

    //     if(i.msg_id == action.payload.msg_id){
    //         //console.log(438,i)
    //         reply_saveMessages_idb([i])
    //     }
    // }
    //console.log(481,stateN)
    return {
        ...stateN
    }
}
const setMsgSelection = (state, action) => {
    return {
        ...state,
        msgSelection: action.payload
    }
}
const setErrorMsg = (state, action) => {
    return {
        ...state,
        error: action.payload
    } 
}
const setConversationsList = (state, action) => {
    return {
        ...state,
        conversation_list: [...action.payload]
    }
}
const setLogindata = (state, action) => {
    // //console.log(action.payload)
    
    return {
        ...state,
        logindata: { ...action.payload },
        filter_status: false
    }
}
const removeOneMsg = (state, action) => {
    let retunData = {}
    if (action.payload.is_reply_msg === 'yes') {
        retunData = {
            ...state,
            reply_data: state.reply_data.filter((v) => v.msg_id !== action.payload.msg_id)
        }

    } else {
        retunData = {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.filter((v) => v.msg_id !== action.payload.msg_id)
                }
            }
        }
    }

    // for(let i of retunData.logindata.active_conv.msgs){
    //     if(i.msg_id == action.payload.msg_id){
    //         saveMessages_idb([i]);

    //     }
    // }
    // for(let i of retunData.reply_data){
    //     if(i.msg_id == action.payload.msg_id){
    //         reply_saveMessages_idb([i]);

    //     }
    // }
   

    return retunData;
}
const sendOneMessage = (state, action) => {
    // //console.log(328,action.payload)
    var myid = action.payload.mention_user === null ? [] : action.payload.mention_user.filter((i) => i === state.logindata.user.id);
    // //console.log(279,myid)
    let popupFilterType = localStorage.getItem('popupFilterType') || ''
    //console.log(553,popupFilterType)
    if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id &&  popupFilterType !== 'thread_view') {
       
        instanceMsgSeenAPI({
            conversation_id: action.payload.conversation_id,
            msg_ids: [action.payload.msg_id],
            is_reply_msg: 'no',
            root_msg_id: ''
        });
        
        // //console.log('idle_status',state.idle_status)
        if (state.idle_status) {
            action.payload.msg_status = [state.logindata.user.id];
        }
        // //console.log(341,action.payload)
        let filter_active_for = localStorage.getItem('filter_active_for');
        let popupFilter = localStorage.getItem('popupFilter');
        //console.log(413, filter_active_for, popupFilter)
        if (filter_active_for  && popupFilter ) {
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,

                    },
                    pin_convs: state.logindata.pin_convs.map((c) => c.conversation_id === action.payload.conversation_id ? { ...c, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1),
                    unpin_convs: state.logindata.unpin_convs.map((c) => c.conversation_id === action.payload.conversation_id ? { ...c, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1)
                }
            }
        } else {

        }
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: [
                        ...state.logindata.active_conv.msgs,
                        action.payload

                    ]
                    // (filter_active_for === null && popupFilter === null) || (filter_active_for === '' && popupFilter === '') ? [
                    //     ...state.logindata.active_conv.msgs,
                    //     action.payload

                    // ]:state.logindata.active_conv.msgs
                },
                pin_convs: state.logindata.pin_convs.map((c) => c.conversation_id === action.payload.conversation_id ? { ...c, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1),
                unpin_convs: state.logindata.unpin_convs.map((c) => c.conversation_id === action.payload.conversation_id ? { ...c, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1)
            }
        }
    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.logindata.pin_convs.map((c) => c.conversation_id === action.payload.conversation_id ? { ...c, urmsg: action.payload.sender !== state.logindata.user.id ? ((c.urmsg || 0) + 1) : c.urmsg, mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? (c.mention_user = 1) : c.mention_user, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1),
                unpin_convs: state.logindata.unpin_convs.map((c) => c.conversation_id === action.payload.conversation_id && c.sender !== state.logindata.user.id ? { ...c, urmsg: action.payload.sender !== state.logindata.user.id ? ((c.urmsg || 0) + 1) : c.urmsg, mention_user: action.payload.sender !== state.logindata.user.id && myid.length > 0 ? c.mention_user = 1 : c.mention_user, last_msg_time: action.payload.created_at } : c).sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1)
            }
        }
    }
}
const setPinStatus = (state, action) => {
    // //console.log(state.logindata);

    if (action.payload.action === 'pin') {
        let pindata = state.logindata.unpin_convs.filter((i) => i.conversation_id === action.payload.conversation_id);
        let unpindata = state.logindata.unpin_convs.filter((i) => i.conversation_id !== action.payload.conversation_id);
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    details: {
                        ...state.logindata.active_conv.details,
                        pin: [...state.logindata.active_conv.details.pin, state.logindata.user.id]
                    }
                },
                pin_convs: [...state.logindata.pin_convs, ...pindata].sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1),
                unpin_convs: [...unpindata].sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1)
            },
        }
    } else {
        let pindata = state.logindata.pin_convs.filter((i) => i.conversation_id !== action.payload.conversation_id);
        let unpindata = state.logindata.pin_convs.filter((i) => i.conversation_id === action.payload.conversation_id);
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    details: {
                        ...state.logindata.active_conv.details,
                        pin: state.logindata.active_conv.details.pin.filter((i) => i !== state.logindata.user.id)
                    }
                },
                pin_convs: [...pindata].sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1),
                unpin_convs: [...unpindata, ...state.logindata.unpin_convs].sort((a, b) => a.last_msg_time > b.last_msg_time ? -1 : 1)
            },
        }

    }
}

const updateUnreadNoficationCounter = (state, action) => {
    return {
        ...state,
        logindata: {
            ...state.logindata,
            total_unread_notification: (state.logindata.total_unread_notification - action.payload)

        }
    }
}

const notificationCounterSet = (state, action) => {
    return {
        ...state,
        logindata: {
            ...state.logindata,
            total_unread_notification: (state.logindata.total_unread_notification + 1),
        }

    }
}
const updateMsgCounter = (state, action) => {
    // //console.log(385,action.payload)
    let m = 0;
    let l = (state.logindata.total_r_mention_user - action.payload.reply_mention)
    if (l > 0) {
        m = l;
    }

    if (action.payload.is_reply_msg === 'yes') {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map(v => v.conversation_id === action.payload.conversation_id && v.msg_id === action.payload.root_msg_id ? { ...v, unread_reply: (v.unread_reply - action.payload.read) } : v)
                },
                pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: v.r_mention_user = 0, urreply: (v.urreply - action.payload.read) } : v),
                unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, r_mention_user: v.r_mention_user = 0, urreply: (v.urreply - action.payload.read) } : v),
                total_unread_reply: (((state.logindata.total_unread_reply || 0) - action.payload.read) || 0),
                total_r_mention_user: m
            }
        }
    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, mention_user: (v.mention_user = 0), urmsg: (v.urmsg - action.payload.read) } : v),
                unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, mention_user: (v.mention_user = 0), urmsg: (v.urmsg - action.payload.read) } : v),
            }
        }
    }
}
const updateMsgTag = (state, action) => {
    // //console.log(454,state)
    // //console.log(454, action.payload)
    if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv, msgs: state.logindata.active_conv.msgs?.map((v) => v.msg_id === action.payload.msg_id ?
                        {
                            ...v, has_tag_text: action.payload.conversation_id === action.payload.file_id ?
                                [...v.has_tag_text.filter((t) => action.payload.removetag?.indexOf(t) === -1), ...action.payload.newtag] :
                                v.has_tag_text, all_attachment: v.all_attachment.map((f) => f.id === action.payload.file_id || action.payload.file_id === action.payload.conversation_id ?
                                    {
                                        ...f, tag_list_details: [...f.tag_list_details?.filter((t) => action.payload.removetag?.indexOf(t.tag_id) === -1),
                                        ...action.payload.newtag_tag_data.filter(t => f.tag_list?.indexOf(t.tag_id) === -1)],
                                        tag_list: [...f.tag_list?.filter((t) => action.payload.removetag.indexOf(t) === -1),
                                        ...action.payload.newtag?.filter(t => f.tag_list.indexOf(t) === -1)]
                                    } : f)
                        } : v)
                },
            },
            reply_data: action.payload.is_reply && state.active_thread !== null && state.active_thread.msg_id === action.payload.root_msg_id ? [...state.reply_data.map((v) => v.msg_id === action.payload.msg_id ? { ...v, has_tag_text: action.payload.conversation_id === action.payload.file_id ? [...v.has_tag_text.filter((t) => action.payload.removetag.indexOf(t) === -1), ...action.payload.newtag] : v.has_tag_text, all_attachment: v.all_attachment.map((f) => f.id === action.payload.file_id || action.payload.file_id === action.payload.conversation_id ? { ...f, tag_list_details: [...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1), ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)], tag_list: [...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1), ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)] } : f) } : v)] : [...state.reply_data],
            change_file_data: action.payload
        }
    } else {
        return {
            ...state,
            change_file_data: action.payload
        }
    }
}
const updateTaskTag = (state, action) => {

    var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    if (task_id !== action.payload.task_id) return { ...state }

    return {
        ...state,
        DiscussionList: [...state.DiscussionList.map((v) => v.task_id === action.payload.task_id ? {
            ...v, all_attachment: v.all_attachment.map((f) => f.id === action.payload.file_id ?
                    {
                        ...f, tag_list_details: [...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1),
                        ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)],
                        tag_list: [...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1),
                        ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)]
                    } : f)
        } : v)
    ],
    }
}
const updateTicketTag = (state, action) => {

    // var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    // if (task_id !== action.payload.task_id) return { ...state }

    return {
        ...state,
        maintenanceFileList: [
            ...state.maintenanceFileList.map((f) => f.id === action.payload.file_id ?
            {
                ...f, tag_list_details: [...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1),
                ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)],
                tag_list: [...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1),
                ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)]
            } : f)
        ],
    }
}

const updateMsgRead = (state, action) => {

    // //console.log(454,state)
    // //console.log(454, action.payload)
    if (action.payload.conversation_id === state.logindata.active_conv.details.conversation_id) {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv, msgs: state.logindata.active_conv.msgs.map((v) => v.msg_id === action.payload.msg_id ?
                        { ...v, is_read: true } : v)
                },
            }
        }
    } else {
        return {
            ...state,
            change_file_data: action.payload
        }
    }
}
const updateMyProfile = (state, action) => {
    // //console.log(468,action.payload.id === state.logindata.user.id)


    if (action.payload.id === state.logindata.user.id) {
        let data = {
            ...state,
            logindata: {
                ...state.logindata,
                user: {
                    ...state.logindata.user,
                    ...action.payload,
                },
                pin_convs: state.logindata.pin_convs.map((v) => v.conversation_id === action.payload.id ? { ...v, title: action.payload.firstname + ' ' + action.payload.lastname, conv_img: action.payload.img } : v),
                active_conv: {
                    ...state.logindata.active_conv,
                    details: state.logindata.active_conv.details.conversation_id === action.payload.id ? { ...state.logindata.active_conv.details, title: action.payload.firstname + ' ' + action.payload.lastname, conv_img: action.payload.img } : state.logindata.active_conv.details,
                    msgs: state.logindata.active_conv.msgs.map(v => v.sender === action.payload.id ? { ...v, sendername: action.payload.firstname + ' ' + action.payload.lastname, senderimg: action.payload.img } : v)
                }
            },
            company_users:{...state.company_users,[state.logindata.user.id]:{...state.company_users[state.logindata.user.id],...action.payload}}
        }
        localStorage.setItem('user',JSON.stringify(data.logindata.user));
        localStorage.setItem('logindata',JSON.stringify(data.logindata));

        let company_users = data.company_users;
            if(action.payload.id === state.logindata.user.id){
                if(company_users[action.payload.id]){
                    company_users[action.payload.id] = {...company_users[action.payload.id],...action.payload}
                }
                
            }
        localStorage.setItem('company_users',JSON.stringify(company_users));
        return data;


    } else {
        return {
            ...state
        }
    }
}
const returnUpdateKeys = (main, action) => {
    return {
        ...main,
        b_unit_id: action.payload.b_unit_id,
        b_unit_id_name: action.payload.b_unit_id_name,
        team_id: action.payload.team_id,
        participants: action.payload.participants,
        participants_admin: action.payload.participants_admin,
        participants_guest: action.payload.participants_guest,
        participants_details: getparticipants_details(action.payload.participants),
        privacy: action.payload.privacy,
        title: action.payload.title,
        conv_img: action.payload.conv_img,
        tag_list: action.payload.tag_list
    }
}
const updateRoom = (state, action) => {
    // //console.log(462,state.logindata.active_conv.details.conversation_id === action.payload.conversation_id)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            pin_convs: state.logindata.pin_convs.map((v) => v.conversation_id === action.payload.conversation_id ? returnUpdateKeys(v, action) : v),
            unpin_convs: state.logindata.unpin_convs.map((v) => v.conversation_id === action.payload.conversation_id ? returnUpdateKeys(v, action) : v),
            active_conv:
            {
                ...state.logindata.active_conv,
                details: state.logindata.active_conv.details.conversation_id === action.payload.conversation_id ? returnUpdateKeys(state.logindata.active_conv.details, action) : state.logindata.active_conv.details,

            }
        }
    }
}
const kickOutRoom = (state, action) => {
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                details: {
                    ...state.logindata.active_conv.details,
                    temp_user: state.logindata.active_conv.details.conversation_id === action.payload.conversation_id ? [state.logindata.user.id] : state.logindata.active_conv.details.temp_user,
                }
            },
            pin_convs: state.logindata.pin_convs.map((v) => v.conversation_id === action.payload.conversation_id ? { ...v, temp_user: [state.logindata.user.id] } : v),
            unpin_convs: state.logindata.unpin_convs.map((v) => v.conversation_id === action.payload.conversation_id ? { ...v, temp_user: [state.logindata.user.id] } : v),

        }
    }
}

const convertToTask = (state, action) => {
    // //console.log(454,action.payload)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                msgs: state.logindata.active_conv.msgs.map(v => v.msg_id === action.payload.msg_id ? { ...action.payload } : v)
            }
        }
    }
}
const readAllMsg = (state, action) => {
    // //console.log(454,action.payload,state.logindata)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                msgs: state.logindata.active_conv.msgs.map(v => ({ ...v, msg_status: v.msg_status.filter(i => i !== state.logindata.user.id) }))
            }
        }
    }
}
const file_share_link_create = (state, action) => {

    if (state.logindata.active_conv.details.conversation_id === action.payload.conversation_id) {
        // //console.log(562,action.payload)
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : v)
                }
            },
            active_thread: state.active_thread !== null && state.active_thread.msg_id === action.payload.msg_id ? { ...state.active_thread, all_attachment: state.active_thread.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : state.active_thread,
            reply_data: state.reply_data.map(v => v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.id ? { ...f, url_short_id: action.payload.url_short_id } : f) } : v),
            change_share_link: { ...action.payload },
        }
    } else {
        return {
            ...state,
            change_share_link: { ...action.payload }
        }
    }
}
const removeAttachmentTask = (state, action) => {
    // debugger
    var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    if (task_id === action.payload.task_id) {
        let msg_id = state.DiscussionList.filter((msg) => msg.msg_id === action.payload.reply_for_msgid);
        let has_reply_attach = 0;
        if (msg_id.length) {
            if (!msg_id[0].hasOwnProperty('has_reply_attach')) { msg_id[0].has_reply_attach = 0; }
            has_reply_attach = msg_id[0].has_reply_attach - 1

        }
        
        return {
            ...state,
            CostFile: [...state.CostFile.filter(f => f.id !== action.payload.file_id)],
            DiscussionList: [...state.DiscussionList.map(v =>
                v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.filter(f => f.id !== action.payload.file_id) } :
                    v.msg_id === action.payload.reply_for_msgid ? { ...v, has_reply_attach: has_reply_attach } : v)],

        }
    } else {
        return {
            ...state
        }
    }
}

const removeAttachment = (state, action) => {

    if (state.logindata.active_conv.details.conversation_id === action.payload.conversation_id) {
        let msg_id = state.logindata.active_conv.msgs.filter((msg) => msg.msg_id === action.payload.reply_for_msgid);
        let has_reply_attach = 0;
        if (msg_id.length) {
            if (!msg_id[0].hasOwnProperty('has_reply_attach')) { msg_id[0].has_reply_attach = 0; }
            has_reply_attach = msg_id[0].has_reply_attach - 1

        }
        let reply_data = state.reply_data.map((v) => {
            if (v.msg_id === action.payload.msg_id) {
                v.all_attachment = v.all_attachment.filter(f => f.id !== action.payload.file_id)
            }
            return v;
        });


        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map(v =>
                        v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.filter(f => f.id !== action.payload.file_id) } :
                            v.msg_id === action.payload.reply_for_msgid ? { ...v, has_reply_attach: has_reply_attach } : v)
                }
            },
            reply_data: reply_data
        }
    } else {
        return {
            ...state
        }
    }
}
const readAllReplyMsg = (state, action) => {
    return {
        ...state,
        reply_data: state.reply_data.map(v => ({ ...v, msg_status: v.msg_status.filter(i => i !== state.logindata.user.id) }))
    }
}
const updateLoginData = (state, action) => {
    let company_users = false;
    if(action.payload.all_users){
         company_users = {};
        for(let i of action.payload.all_users){
          company_users[i.id] = i
        }
    }
    return {
        ...state,
        logindata: {
            ...state.logindata,
            pin_convs: state.logindata.pin_convs.map(v =>
                action.payload.conversations_list_unread?.indexOf(v.conversation_id) > -1 ?
                    {
                        ...v,
                        mention_user: action.payload.conversations_list_unread_counter[v.conversation_id].mention_user,
                        urmsg: action.payload.conversations_list_unread_counter[v.conversation_id].urmsg,
                        urreply: action.payload.conversations_list_unread_counter[v.conversation_id].urreply
                    } : v),

            unpin_convs: state.logindata.unpin_convs.map(v =>
                action.payload.conversations_list_unread?.indexOf(v.conversation_id) > -1 ?
                    {
                        ...v,
                        r_mention_user: action.payload.conversations_list_unread_counter[v.conversation_id].r_mention_user, mention_user: action.payload.conversations_list_unread_counter[v.conversation_id].mention_user,
                        urmsg: action.payload.conversations_list_unread_counter[v.conversation_id].urmsg,
                        urreply: action.payload.conversations_list_unread_counter[v.conversation_id].urreply
                    } : v),
            ...action.payload
        },
        company_users:company_users?company_users:state.company_users
    }
}
const updateCompany_users = (state,action)=>{

    return {
        ...state,
        company_users:action.payload
    }
}
const userIdleStatus = (state, action) => {
    return {
        ...state,
        idle_status: action.payload
    }
}
const setFlaggedConv = (state, action) => {
    return {
        ...state,
        flagged_conv: [...action.payload]
    }
}

const setArchiveConv = (state, action) => {
    return {
        ...state,
        archived_conv: [...action.payload]
    }
}
const setPrivateConv = (state, action) => {
    return {
        ...state,
        private_conv: [...action.payload]
    }
}
const setLinkConv = (state, action) => {
    return {
        ...state,
        link_conv: [...action.payload]
    }
}
const setTaggedConv = (state, action) => {
    return {
        ...state,
        tagged_conv: [...action.payload]
    }
}
const removeMessage = (state, action) => {
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                msgs: state.logindata.active_conv.msgs.filter(v => v.msg_id !== action.payload.msg_id)
            }
        }

    }
}

// const setOnlineUsers = (state,action)=>{
//     return {...state,online_users:[...action.payload]}
// }

const setOnlineUsers = (state, action) => {
    if (action.payload) {

        return { ...state, online_users: [...action.payload] }
    } else {
        return state;
    }
}

const setOnlineUsersToken = (state, action) => {
    return { ...state, online_users_token: action.payload }
}

const checkFilesText = (msg, text) => {
    let d = msg.all_attachment.filter(v => v.originalname.toLowerCase().indexOf(text.toLowerCase()) > -1);
    if (d.length > 0) {
        return true;
    } else {
        return false;
    }
}
function updateMessageStarTask(state, action) {

    var task_id = state.task_panel_options.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    if (task_id === action.payload.task_id) {
        return {
            ...state,
            DiscussionList: [
                ...state.DiscussionList.map(v =>
                    v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v)
            ],
        }
    } else {
        return {
            ...state
        }
    }
}
function updateMessageStarTicket(state, action) {

    // var task_id = state.task_panel_options.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
    // if (task_id === action.payload.task_id) {
        // return {
        //     ...state,
        //     maintenanceFileList: [
        //         ...state.DiscussionList.map(v =>
        //             v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v)
        //     ],
        // }
        return {
            ...state,
            maintenanceFileList: [
                ...state.maintenanceFileList.map((f) => f.id === action.payload.file_id ?
                { ...f, star: action.payload.star } : f)
            ],
        }
        
    // } else {
    //     return {
    //         ...state
    //     }
    // }
}

function updateMessageStar(state, action) {

    if (action.payload.is_reply_msg === 'yes') {
        let reply_data = state.reply_data.map(v =>
            v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v);
        return {
            ...state,
            reply_data: reply_data
        }


    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                active_conv: {
                    ...state.logindata.active_conv,
                    msgs: state.logindata.active_conv.msgs.map(v =>
                        v.msg_id === action.payload.msg_id ? { ...v, all_attachment: v.all_attachment.map(f => f.id === action.payload.file_id ? { ...f, star: action.payload.star } : f) } : v)
                }
            },
        }

    }


}
function updateArchiveConv(state, action) {
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                details: {
                    ...state.logindata.active_conv.details,
                    archive: action.payload.conversation_id === state.logindata.active_conv.details.conversation_id ? action.payload.archive : state.logindata.active_conv.details.archive
                }
            },

            pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
            unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v)
        },
        tagged_conv: state.tagged_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
        link_conv: state.link_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
        private_conv: state.private_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
        flagged_conv: state.flagged_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
        archived_conv: state.archived_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, archive: action.payload.archive } : v),
    }
}
function activeOnlyMyConv(state, action) {
    if (action.payload) {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.activeOnlyOtherConvdata ? state.pin_convs.filter(v => v.created_by === state.logindata.user.id && v.group === 'yes') : state.logindata.pin_convs.filter(v => v.created_by === state.logindata.user.id && v.group === 'yes'),
                unpin_convs: state.activeOnlyOtherConvdata ? state.unpin_convs.filter(v => v.created_by === state.logindata.user.id && v.group === 'yes') : state.logindata.unpin_convs.filter(v => v.created_by === state.logindata.user.id && v.group === 'yes')
            },
            activeOnlyOtherConvdata: false,
            activeOnlyMyConvdata: action.payload,
            pin_convs: state.activeOnlyOtherConvdata ? state.pin_convs : state.logindata.pin_convs,
            unpin_convs: state.activeOnlyOtherConvdata ? state.unpin_convs : state.logindata.unpin_convs
        }

    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.pin_convs,
                unpin_convs: state.unpin_convs
            },
            activeOnlyOtherConvdata: false,
            activeOnlyMyConvdata: action.payload,
            pin_convs: state.logindata.pin_convs,
            unpin_convs: state.logindata.unpin_convs
        }
    }
}
function activeOnlyOtherConv(state, action) {
    if (action.payload) {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.activeOnlyMyConvdata ? state.pin_convs.filter(v => v.created_by !== state.logindata.user.id && v.group === 'yes') : state.logindata.pin_convs.filter(v => v.created_by !== state.logindata.user.id && v.group === 'yes'),
                unpin_convs: state.activeOnlyMyConvdata ? state.unpin_convs.filter(v => v.created_by !== state.logindata.user.id && v.group === 'yes') : state.logindata.unpin_convs.filter(v => v.created_by !== state.logindata.user.id && v.group === 'yes')
            },
            activeOnlyMyConvdata: false,
            activeOnlyOtherConvdata: action.payload,
            pin_convs: state.activeOnlyMyConvdata ? state.pin_convs : state.logindata.pin_convs,
            unpin_convs: state.activeOnlyMyConvdata ? state.unpin_convs : state.logindata.unpin_convs
        }

    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.pin_convs,
                unpin_convs: state.unpin_convs
            },
            activeOnlyMyConvdata: false,
            activeOnlyOtherConvdata: action.payload,
            pin_convs: state.logindata.pin_convs,
            unpin_convs: state.logindata.unpin_convs
        }
    }
}
function closeConversation(state, action) {
    // //console.log(757,state.archived_conv)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            active_conv: {
                ...state.logindata.active_conv,
                details: {
                    ...state.logindata.active_conv.details,
                    close_for: action.payload.conversation_id === state.logindata.active_conv.details.conversation_id ? action.payload.close_room : state.logindata.active_conv.details.close_for
                }
            },
            pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
            unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v)
        },
        tagged_conv: state.tagged_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
        link_conv: state.link_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
        private_conv: state.private_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
        flagged_conv: state.flagged_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
        archived_conv: state.archived_conv.map(v => v.conversation_id === action.payload.conversation_id ? { ...v, close_for: action.payload.close_room } : v),
    }
}
function addNewUser(state, action) {
    // //console.log(757,state.archived_conv)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            all_users: [...state.logindata.all_users, action.payload]
        }

    }
}
function setAllUsers(state, action) {
    // //console.log(757,state.archived_conv)
    return {
        ...state,
        logindata: {
            ...state.logindata,
            all_users: [...action.payload]
        }

    }
}
function leave_conversation(state, action) {
    // //console.log(757,state.archived_conv)
    if (state.logindata.active_conv.details.conversation_id === action.payload.conversation_id) {
        window.location.href = '/connect/' + state.logindata.user.id;
    } else {
        return {
            ...state,
            logindata: {
                ...state.logindata,
                pin_convs: state.logindata.pin_convs.filter(v => v.conversation_id !== action.payload.conversation_id),
                unpin_convs: state.logindata.unpin_convs.filter(v => v.conversation_id !== action.payload.conversation_id)
            }

        }
    }
}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'userIdleStatus':
            return userIdleStatus(state, action);

        case 'deleteRoom':
            return deleteRoom(state, action);

        case 'setOnlineUsers':
            return setOnlineUsers(state, action)

        case 'setOnlineUsersToken':
            return setOnlineUsersToken(state, action)

        case 'addNewRoom':
            return addNewRoom(state, action);

        case 'updateRoom':
            return updateRoom(state, action);

        case 'kickOutRoom':
            return kickOutRoom(state, action);

        case 'setActiveThread':
            return setActiveThread(state, action)

        case 'setReplyData':
            return setReplyData(state, action)

        case 'setMuteDataOnProps':
            return setMuteDataOnProps(state, action);

        case 'setOneReplyData':
            return setOneReplyData(state, action);

        case 'setMsgSelection':
            return setMsgSelection(state, action)

        case 'setErrorMsg':
            return setErrorMsg(state, action)

        case 'setConversationsList':
            return setConversationsList(state, action)

        case 'setLogindata':
            return setLogindata(state, action);

        case 'updateMyProfile':
            return updateMyProfile(state, action);

        case 'updateLinkMsg':
            return {
                ...state,
                link_msg: action.payload
            }

        case 'filterLinkSTR':
            return filterLinkSTR(state, action);

        case 'activeOnlyOtherConv':
            return activeOnlyOtherConv(state, action);

        case 'updateActiveConv': {
            
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        details: { ...action.payload.active_conv.details },
                        msgs: [...action.payload.active_conv.msgs]
                    }
                },
                pin_convs: state.logindata.pin_convs.map(v => v.conversation_id === action.payload.active_conv.details.conversation_id ? { ...v, load_msg: [...action.payload.active_conv.msgs] } : v),
                unpin_convs: state.logindata.unpin_convs.map(v => v.conversation_id === action.payload.active_conv.details.conversation_id ? { ...v, load_msg: [...action.payload.active_conv.msgs] } : v),
                filter_status: false
            }

        }
        case 'updateCompanyName': {
            
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    user: {
                        ...state.logindata.user,
                        company_name: action.payload,
                       
                    }
                }
            }

        }
        case 'set_Task_panel_option': {

            return {
                ...state,
                task_panel_options: action.payload,
                task_id_active: action.payload.data?._id
            }

        }
        case 'set_task_id_active' : {
            return {
                ...state,
                task_id_active: action.payload
            }
        }
        case 'update_Task_panel_option': {
            return {
                ...state,
                task_panel_options: action.payload
            }

        }
        case 'updateOldActiveConv': {

            //console.log(223,action.payload.active_conv.details)
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        details: {
                            ...action.payload.active_conv.details
                        },
                        msgs: [...action.payload.active_conv.msgs, ...state.logindata.active_conv.msgs]
                    }
                },
                filter_status: false
            }

        }
        case 'updateOnlymsg': {
            // //console.log(223,action.payload.active_conv.details)
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        msgs: [...action.payload.active_conv.msgs, ...state.logindata.active_conv.msgs]
                    }
                },
                filter_status: false
            }

        }
        case 'setCompanyLogin': {
            return {
                ...state,
                company_login: action.payload
            }

        }
        case 'setMessages_loader': {
            return {
                ...state,
                msg_loader: action.payload
            }

        }
        case 'setOpenMyConv': {
            return {
                ...state,
                open_my_conv: action.payload
            }

        }
        
        case 'updateOneMsg':
            if(action.payload.task_id) return updateOneMsgTask(state, action);
            return updateOneMsg(state, action);

        case 'removeOneMsg':
            return removeOneMsg(state, action);

        case 'sendOneMessage':
            return sendOneMessage(state, action);
        case 'change_message': {
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        msgs: [...action.payload]
                    }
                },
                filter_status: true
            }

        }
        case 'add_pagi_message': {
            // //console.log(740,action.payload,state.logindata.active_conv.msgs)
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        msgs: [...action.payload, ...state.logindata.active_conv.msgs]
                    }
                },
                filter_status: true
            }

        }
        case 'setActiveConvData': {
            return {
                ...state,
                active_conv: action.payload
            }

        }
        case 'is_secret_false_msg': {
            //console.log(1337,state.private_conv)
            return {
                ...state,
                private_conv: state.private_conv.map(c=> c.conversation_id === action.payload ? {...c,total:(c.total-1)}:c)
            }

        }
        case 'set_right_side_loader': {
            return {
                ...state,
                right_side_loader: action.payload
            }

        }
        case 'setConversation_id': {
            return {
                ...state,
                conversation_id: action.payload
            }

        }
        case 'setPinStatus':
            return setPinStatus(state, action);

        case 'leave_conversation':
            return leave_conversation(state, action);

        case 'setUserShortUrl':

            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    user: {
                        ...state.logindata.user,
                        short_id: action.payload.short_id
                    },

                }
            }

        case 'updateMessageIsShow':

            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        msgs: state.logindata.active_conv.msgs.map(v => v.msg_body.toLowerCase().indexOf(action.payload) > -1 || v.url_base_title.toLowerCase().indexOf(action.payload) > -1 || checkFilesText(v, action.payload) ? { ...v, is_show: true } : { ...v, is_show: false })

                    }

                }
            }
        case 'set_active_user_data':

            // state.logindata.active_conv.details.participants_details.map((v,i)=>{
            //     if(v.id == payload.user.id){
            //         return {...v, is_active: payload.user.is_active}
            //     }
            //     return {...v}
            // })
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        details: {
                            ...state.logindata.active_conv.details,
                            participants_details: [...getparticipants_details(state.logindata.active_conv.details.participants).map((v) =>
                                v.id === action.payload.user.id ? { ...v, is_active: action.payload.user.is_active } : { ...v })]
                        },
                        msgs: [...state.logindata.active_conv.msgs.map((v) =>
                            v.sender === action.payload.user.id ? { ...v, sender_is_active: action.payload.user.is_active } : { ...v })]
                    },

                },
            }
            case 'set_eod_user_data':

                return {
                    ...state,
                    logindata: {
                        ...state.logindata,
                        user: {
                            ...state.logindata.user,
                            eod_report: action.payload.eod_report
                        },
    
                    }
                }
                
        case 'setUserMuteAll':

            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    user: {
                        ...state.logindata.user,
                        mute_all: action.payload.mute_all
                    },

                }
            }

        case 'setUserMuteData':
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    user: {
                        ...state.logindata.user,
                        mute: action.payload.mute
                    },

                }
            }

        case 'setConvShortUrl':
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    active_conv: {
                        ...state.logindata.active_conv,
                        details: {
                            ...state.logindata.active_conv.details,
                            short_id: action.payload.short_id
                        }
                    },

                },
            }

        case 'setGlobalLoader':
            return {
                ...state,
                global_loader_status: action.payload
            }

        case 'setThreadedConvData':
            return {
                ...state,
                threadedConvData: action.payload
            }

        case 'setThreadedDetails':
            return {
                ...state,
                threadedDetails: action.payload
            }
         
        // case 'notificationSet': 
        //     return notificationSet(state,action);
        //     
        // setFlaggedConv
        case 'notificationCounterSet':
            return notificationCounterSet(state, action);

        case 'setFlaggedConv':
            return setFlaggedConv(state, action);


        case 'initFlaggedConvData':
            return {
                ...state,
                flagged_conv_data: {

                }
            }

        case 'setFlaggedConvData':
            return {
                ...state,
                flagged_conv_data: {
                    ...state.flagged_conv_data,
                    [action.payload.conversation_id]: action.payload.msgs
                }

            }

        case 'updateFlaggedConvData':
            if (!state.flagged_conv_data[action.payload.conversation_id]) return { ...state };
            let data = action.payload.has_flagged === 'yes' ? [state.logindata.user.id] : [];
            return {
                ...state,
                flagged_conv_data: {
                    ...state.flagged_conv_data,
                    [action.payload.conversation_id]: state.flagged_conv_data[action.payload.conversation_id].map((msg) =>
                        (msg.msg_id === action.payload.msg_id ? { ...msg, [action.payload.field]: data } : msg)
                    )
                },

            }


        case 'update_participant_list': {
                return {
                    ...state,
                    logindata: {
                        ...state.logindata,
                        active_conv: {
                            ...state.logindata.active_conv,
                            details: {
                                ...state.logindata.active_conv.details,
                                participants: action.payload
                            }
                        }
                    }
                }
    
            }

        case 'setArchiveConv':
            return setArchiveConv(state, action);

        case 'setPrivateConv':
            return setPrivateConv(state, action);

        case 'setLinkConv':
            return setLinkConv(state, action);

        case 'setTaggedConv':
            return setTaggedConv(state, action);

        case 'removeAttachment':
            if(action.payload.task_id) return removeAttachmentTask(state, action);
            return removeAttachment(state, action);

        case 'file_share_link_create':
            return file_share_link_create(state, action);

        case 'updateUnreadNoficationCounter':
            return updateUnreadNoficationCounter(state, action);

        case 'updateMsgCounter':
            return updateMsgCounter(state, action);

        case 'updateMsgTag':
            return updateMsgTag(state, action);
        case 'updateTaskTag':
            return updateTaskTag(state, action);    
        case 'updateTicketTag':
            return updateTicketTag(state, action)
        case 'updateMsgRead':
            return updateMsgRead(state, action);
        case 'convertToTask':
            return convertToTask(state, action);

        case 'readAllMsg':
            return readAllMsg(state, action);

        case 'updateLoginData':
            return updateLoginData(state, action);

        case 'readAllReplyMsg':
            return readAllReplyMsg(state, action);

        case 'removeMessage':
            return removeMessage(state, action);

        case 'updateMessageStar':
            if(action.payload.task_id){
                return updateMessageStarTask(state, action)
            }
            if(action.payload.maintenance_id){
                return updateMessageStarTicket(state, action)
            } 
            return updateMessageStar(state, action);

        case 'updateArchiveConv':
            return updateArchiveConv(state, action);

        case 'closeConversation':
            return closeConversation(state, action);

        case 'activeOnlyMyConv':
            return activeOnlyMyConv(state, action);

        case 'addNewUser':
            return addNewUser(state, action);

        case 'setAllUsers':
            return setAllUsers(state, action);
        case 'updateCompany_users':
            return updateCompany_users(state, action);

        case 'setPrivateMsgTitle':
            return {
                ...state,
                logindata: {
                    ...state.logindata,
                    getPrivateMsgTitle: action.payload,
                }
            }

        case 'setCostFile':
            return {
                ...state,
                CostFile: action.payload,

            }

        case 'updateCostFile':
            let _id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
            if (_id !== action.payload.task_id) return { ...state }
            return {
                ...state,
                CostFile: [...state.CostFile, ...action.payload.all_attachment],

            }

        case 'setDiscussionList':
            return {
                ...state,
                DiscussionList: action.payload,
            }
        case 'setMaintenanceFileList':
            return {
                ...state,
                maintenanceFileList: action.payload,
            }
        case 'setMaintenanceActivityList':
            return {
                ...state,
                maintenanceActivityList: action.payload,
            }
        case 'setNotificationList':
                return {
                    ...state,
                    NotificationList: action.payload,
                }    
            
        case 'setTaskData':
            return {
                ...state,
                taskData: action.payload,
            }
        
        case 'addNotificationData':

            var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
            if (task_id !== action.payload.task_id) return { ...state }
            return {
                ...state,
                NotificationList: [action.payload, ...state.NotificationList],

            }
            // return {
            //     ...state,
            //     task_panel_options: {
            //         ...state.task_panel_options,
            //         data: {
            //             ...state.task_panel_options.data,
            //             notification: {
            //                 ...state.task_panel_options.data.notification,
            //                 notifications: [action.payload,...state.task_panel_options.data.notification.notifications]
            //                 }
            //             }
            //         },

            //     }
            // const task_index = state.taskData.findIndex(item => item._id === action.payload.task_id);
            // if (task_index !== -1) {
            //     const updatedTaskData = [...state.taskData];
            //     updatedTaskData[task_index] = action.payload;
            //     updateObj["taskData"] = updatedTaskData;
            //     return {...state, ...updateObj}
            // }
            // return {...state}
        case 'addChecklistData':

            var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
            if (task_id !== action.payload.task_id) return { ...state }
            return {
                ...state,
                task_panel_options: {
                    ...state.task_panel_options,
                    data: {
                        ...state.task_panel_options.data,
                        checklist_id: {
                            ...state.task_panel_options.data.checklist_id,
                            items: [...state.task_panel_options.data.checklist_id?.items,action.payload]

                            }
                        }
                    },

                }
        case 'addTaskData':
            let updateObj = {};
            const existingIndex = state.taskData.findIndex(item => item._id === action.payload._id);
            if (existingIndex !== -1) {
                const updatedTaskData = [...state.taskData];
                updatedTaskData[existingIndex] = action.payload;
                updateObj["taskData"] = updatedTaskData;
            } else {
                updateObj["taskData"] = [action.payload, ...state.taskData];
            }
            return {...state, ...updateObj}

        
        case 'updateDiscussionList':
                var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
                if (task_id !== action.payload.task_id) return { ...state }
                return {
                    ...state,
                    DiscussionList: [...state.DiscussionList, action.payload],
    
                }
        case 'updateMaintenanceFileList':
            // var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
            // if (task_id !== action.payload.task_id) return { ...state }
            return {
                ...state,
                maintenanceFileList: [action.payload,...state.maintenanceFileList],

            }
        case 'updateMaintenanceActivityList':
                // var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
                // if (task_id !== action.payload.task_id) return { ...state }
                return {
                    ...state,
                    maintenanceActivityList: [action.payload,...state.maintenanceActivityList],
    
                }
        case 'deleteMaintenanceFileList':

            return {
                ...state,
                maintenanceFileList: state.maintenanceFileList.filter((msg) => msg.id !== action.payload.file_id)
            }
        case 'updateDiscussionTag':
            var task_id = state.task_panel_options?.data && state.task_panel_options.data._id ? state.task_panel_options.data._id : state.task_panel_options.data?.temp_id;
            if (task_id !== action.payload.task_id) return { ...state }

            return {
                ...state,
                DiscussionList: [...state.DiscussionList, action.payload],
            }
            // return {
            //     ...state,
            //     logindata: {
            //         ...state.logindata,
            //         active_conv: {
            //             ...state.logindata.active_conv, msgs: state.logindata.active_conv.msgs.map((v) => v.msg_id === action.payload.msg_id ?
            //                 {
            //                     ...v, has_tag_text: action.payload.conversation_id === action.payload.file_id ?
            //                         [...v.has_tag_text.filter((t) => action.payload.removetag.indexOf(t) === -1), ...action.payload.newtag] :
            //                         v.has_tag_text, all_attachment: v.all_attachment.map((f) => f.id === action.payload.file_id || action.payload.file_id === action.payload.conversation_id ?
            //                             {
            //                                 ...f, tag_list_details: [...f.tag_list_details.filter((t) => action.payload.removetag.indexOf(t.tag_id) === -1),
            //                                 ...action.payload.newtag_tag_data.filter(t => f.tag_list.indexOf(t.tag_id) === -1)],
            //                                 tag_list: [...f.tag_list.filter((t) => action.payload.removetag.indexOf(t) === -1),
            //                                 ...action.payload.newtag.filter(t => f.tag_list.indexOf(t) === -1)]
            //                             } : f)
            //                 } : v)
            //         },
            //     },
                
            // }
        case 'set_files_hub':
            return {
                ...state,
                files_hub: action.payload,
            }
        case 'set_report_list':
            return {
                ...state,
                report_list: action.payload
            }
        case 'update_report_list':
            const existingReportIndex = state.report_list.findIndex(report => report._id === action.payload._id);
            return {
                ...state,
                report_one_update: action.payload,
                report_list: existingReportIndex >= 0
                    ? [
                        ...state.report_list.slice(0, existingReportIndex),
                        { ...state.report_list[existingReportIndex], ...action.payload },
                        ...state.report_list.slice(existingReportIndex + 1)
                    ]
                    : [...state.report_list, action.payload]
                // report_total_count: existingReportIndex === -1
                //     ? state.report_total_count + 1
                //     : state.report_total_count
            };
        case 'set_report_one_update': 
            return {
                ...state,
                report_one_update: action.payload
            }
        case 'set_report_total_count':
            return {
                ...state,
                report_total_count: action.payload
            }
        case 'inc_report_total_count':
            return {
                ...state,
                report_total_count: state.report_total_count + 1
            }
        case 'set_report_total_stats':
            return {
                ...state,
                report_total_stats: action.payload
            }
        case 'set_report_total_update':
            return {
                ...state,
                report_total_update: action.payload
            }


        case 'set_tags_hub':
            return {
                ...state,
                tags_hub: action.payload,
            }

        case 'setPinnedTag':
            return {
                ...state,
                pinnedTag: action.payload
            } 
        case 'setPinnedTagDetails':
            return {
                ...state,
                pinnedTagDetails: action.payload
            }     
            
        case 'RESET_APP':
            return initialState;

        default: {
            return {
                ...state
            }
        }
    }
}

export default rootReducer;