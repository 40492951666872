import axios from "axios";
import { getToken,getSessionLoginData,getUser } from './Common';
import handleError from './handleError';
import toast from 'react-hot-toast';
import {return_data} from '../config/config';
// import {IDB_get, IDB_insert} from "../Utils/indexedSchema"
import { getOlderMsg,modifyMyMsg,getread_all_replyAPI } from '../Utils/API';


let instance = axios.create({
    baseURL:return_data.baseURL,
    // timeout: 5000,
    headers: {'Authorization': getToken()}
});

const instanceCall = (type) =>{
    var newHeader = {}
    if(type === 'file'){
        newHeader = {'content-type': 'multipart/form-data'}
    }
    var options = {
        // baseURL:return_data.baseURL,
        baseURL:return_data.baseURL,
        // timeout: 5000,
        headers: {'Authorization': getToken(), ...newHeader}
    }
    //console.log(options)
    var instance = axios.create(options);
    return instance;
}

//edit msg
const AddReactionAPI =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/add_reac_emoji',data,{headers: {'Authorization': getToken()}}
        ).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            // handleError(error,reject);
        })
    })
}
//edit msg
const EditMsg =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/edit_msg',data,{headers: {'Authorization': getToken()}}
        ).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            handleError(error,reject);
        })
    })
}
//removeTHis msg
const ForwardMsg =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/forward',data,{headers: {'Authorization': getToken()}}
        ).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            handleError(error,reject);
        })
    })
}
//removeTHis msg
const RemoveThisLine =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/remove_this_line',data,{headers: {'Authorization': getToken()}}
        ).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            handleError(error,reject);
        })
    })
}
//forDelete msg
const DeleteMsg =(data)=>{
    ;
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/delete_msg',data,{headers: {'Authorization': getToken()}}).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            handleError(error,reject);
        })
    })
}
//forDelete msg
const getReactionUser =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/per_message/get_emoji_user_list',data,{headers: {'Authorization': getToken()}}).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            // handleError(error,reject);
        })
    })
}

//for flag unplag msg
const flagUnplagAPI =(data)=>{
    return new Promise((resolve,reject)=>{
        //console.log('120 flag',data)
        instance.post('/per_message/flag_unflag',data,{headers: {'Authorization': getToken()}}).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            // handleError(error,reject);
        })
    })
}
//for flag unplag msg
const CreateConversation =(data)=>{
    //console.log(133,getSessionLoginData())
    
    return new Promise((resolve,reject)=>{
        instance.post('/conversation/create_room',data,{headers: {'Authorization': getToken()}}).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(error.response)
            // handle error
            handleError(error,reject);
        })
    })
}
//for conversation pin/unpin
const pinUnpinAPI =(data)=>{
    return new Promise((resolve,reject)=>{
        instance.post('/home/pin_unpin',data,{headers: {'Authorization': getToken()}}).then(function (response) {
            // handle success
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            handleError(error,reject);
        })
    })
}
//for create group data
const getCreateGroupData =()=>{
    return new Promise(async (resolve,reject)=>{
        // let all_bunits = await IDB_get('business_unit',{company_id:getUser().company_id})
        // let all_teams = await IDB_get('team',{company_id:getUser().company_id})
        // let all_users = await IDB_get('user',{company_id:getUser().company_id})
        // resolve({
        //     success: true,
        //     all_bunits:all_bunits,
        //     all_teams:all_teams,
        //     all_users:all_users
        // });
        var check = navigator.onLine ? true : false; 
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.get('/conversation/get_room_create_data', {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                
                //console.log(500,response.data);
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(157,error.response)
                // handle error
                reject({type:'error',...error});
            })
        }
    })
}

//for get Tag data
const getTagAPI = (team_id,upload_type)=>{
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.get(`/tag/get_tag_lists?team_id=${team_id}&upload_type=${upload_type}`, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                resolve({
                    public_tag:[],
                    private_tag:[]
                });
                // reject({type:'error',...error});
            })
        }
    })
}

//for get Tag data
const CreatePrivateTagAPI =(data)=>{
    //console.log(188,data)
    // debugger
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.post('/tag/create_new_tag',data, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}

//for Assign Tag into file
// const AssignTagIntoFileAPI =(data)=>{
//     // if(data.newtag_tag_data.connected_user_ids) delete data.newtag_tag_data;
//     return new Promise(async (resolve,reject)=>{
//         var check = navigator.onLine ? true : false;
//         if(!check){
//             reject({status:false,type:'internet', message:'No Internet'});
//         }else{
//             //console.log(233,data)
//             let file = (await IDB_get('file',{id:data.file_id}))[0];
//             if(data.removetag.length > 0){
//                 file = {...file,
//                     tag_list:file.tag_list.filter(v=> data.removetag.indexOf(v) == -1)
//                 };
//             }else{
//                 file = {...file,
//                     tag_list:[...file.tag_list,...data.newtag]
//                 };
//             }
            
//             //console.log(257,file)
//             await IDB_insert('file',file);
//             instance.post('/tag/add_remove_tag_into_file',data, {
//                 headers: {'Authorization': getToken()}
//               })
//             .then(function (response) {
//                 resolve(response.data);
//             })
//             .catch(function (error) {
//                 //console.log(223,error)
//                 reject({type:'error',...error});
//             })
//         }
//     })
// }
const AssignTagIntoFileAPI =(data)=>{
    // if(data.newtag_tag_data.connected_user_ids) delete data.newtag_tag_data;
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            //console.log(233,data)
            instance.post('/tag/add_remove_tag_into_file',data, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}

//for Assign Tag into file
const AssignTagIntoMsgAPI =(data)=>{
    // if(data.newtag_tag_data) delete data.newtag_tag_data;
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.post('/tag/add_remove_tag_into_msg',data, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}
//for filter message 
const FilterMessageAPI =(type,conversation_id)=>{
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.get(`/home/filter/${type}/${conversation_id}`, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}
const FilterMessageOLDAPI =(data)=>{
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            let url = `/home/filter?page=${data.page}&type=${data.type}&name=${data.name}&conversation_id=${data.conversation_id}`
            if(data.name === 'str'){
                url = `/home/filter?page=${data.page}&type=${data.type}&name=${data.name}&conversation_id=${data.conversation_id}&str=${data.str}`
            }
            instance.get(url, {
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
                // //console.log(44444,response.data)
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}

//for clear message 
const ClearMsgAPI = (data)=>{
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.post(`/home/clear_conversation`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}
//for delete room 
const DeleteRoomAPI = (data)=>{
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        }else{
            instance.post(`/conversation/delete_room`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}


const UpdateRoomAPI = (data) => {
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        } else {
            //console.log(287,data)
            instance.post(`/conversation/update_room`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error)
                reject({type:'error',...error});
            })
        }
    })
}
const getThreadConversationAPI = (data) => {

    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        } else {
            // //console.log(287,data)
            instance.post(`/home/total_unread_reply`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data.result);
            })
            .catch(function (error) {
                //console.log(223,error.response)
                reject({type:'error',...error});
            })
        }
    })
}

const updateProfileAPI = (data) => {
    return new Promise((resolve,reject)=>{
        var check = navigator.onLine ? true : false;
        if(!check){
            reject({status:false,type:'internet', message:'No Internet'});
        } else {
            //console.log(287,data)
            instance.post(`/users/update_my_profile`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error.response)
                reject({type:'error',...error});
            })
        }
    })
}

const updateUserAPI = (data) => {
    return new Promise((resolve,reject)=>{
        // var check = navigator.onLine ? true : false;
        // if(!check){
        //     reject({status:false,type:'internet', message:'No Internet'});
        // } else {
            //console.log(287,data)
            instance.post(`/users/update_user`, data,{
                headers: {'Authorization': getToken()}
              })
            .then(function (response) {
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(223,error.response)
                reject({type:'error',...error});
            })
        // }
    })
}

const removeProfileImageAPI =(user_id)=>{
    return new Promise((resolve, reject) => {
        instance.post('/users/remove_profile_img',{user_id},{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}

const SetNewPasswordAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(394, data);
        instance.post('/users/set_new_password',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}

const getFilesSummaryAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(412, data);
        instance.post('/home/get_file_gallery_summary',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}
const getPagiFileAPI =(data)=>{
    return new Promise((resolve, reject) => {
        let d = '';
        if(data.conversation_id !== undefined){
            d = data.conversation_id;
            delete data.conversation_id;
        }else{
            d = data.conversation_ids;
            delete data.conversation_ids;
        }

        if(typeof(d) === 'object'){
            data['conversation_ids'] = d;
        }else{
            data['conversation_id'] = d;
        }

        // //console.log('From API' ,data)
        instance.post('/home/get_file_gallery',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            // //console.log(21,data)
            //console.log('get_file_gallery:res:',response.data)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}
const otherTagDetails =(tag_id,conversation_id)=>{
    return new Promise((resolve, reject) => {
        // //console.log('From API' ,data)
        let stringV = `?tag_id=${tag_id}`
        if(conversation_id){
            stringV = stringV+`&&conversation_id=${conversation_id}`
        }
        instance.get(`/tag/others_tag_details${stringV}`,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            // //console.log(21,data)
            //console.log('get_file_gallery:res:',response.data)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}

const restoreFile =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(450, data);
        
        instance.patch('/per_message/file_restore',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            // //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}

const ForwardFilesAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(470, data);
        instance.post('/per_message/file_share',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            // //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}

const LeaveRoomAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(489, data);
        instance.post('/conversation/leave_room',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}
const clearNotificationAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(507, data);
        instance.post('/notification/delete',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}
const convertTaskAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(525, data);
        instance.post('/per_message/msg_task',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            handleError(error,reject);
        })
    })
}

const checkAPIserver =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(544, data);
        instance.post('/check',data,{
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}

const getCallingUrl =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(560, data);
        
        instance.post('/voip_api/getCallingUrl',data,{
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}
const updateSecretUsers =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(560, data);
        
        instance.post('/per_message/edit_private_participants',data,{
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}
const setCallingUrl =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(576, data);
        
        instanceCall().post('/voip_api/setCallingUrl',data,{
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}
const createLinkAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(592, data);
        instanceCall().patch('/home/file_share_link_create',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}
const file_share_link_removeAPI =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(611, data);
        instanceCall().patch('/home/file_share_link_remove',data,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}
const archiveConversation =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(611, data);
        instanceCall().patch('/conversation/room_archive?conversation_id='+data.conversation_id+'&archive='+data.archive+'',{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error)
            // handle error
            reject(error);
        })
    })
}
const closeConversation =(data)=>{
    return new Promise((resolve, reject) => {
        instanceCall().patch('/conversation/close_room?conversation_id='+data.conversation_id+'&close_room='+data.close_room+'',{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}
const archiveCount =()=>{
    return new Promise((resolve, reject) => {
        instanceCall().get('/conversation/archive_count',{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}
const resendEmailAPI =(data)=>{
    return new Promise((resolve, reject) => {
        instanceCall().get(`/users/teammate_invite_resend?email=${data.email}&conversation_id=${data.conversation_id}`,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}
const getUserTeamData =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(775,data)
        instanceCall().get(`/team/get_team_lists_by_uid?id=${data}`,{
            headers: {
                'Authorization': getToken()
            }
        }).then(function (response) {
            //console.log(22,response)
            resolve(response);
        })
        .catch(function (error) {
            //console.log(223,error.response)
            // handle error
            reject(error);
        })
    })
}

const mongoObjectId = function () {
    var timestamp = (new Date().getTime() / 1000 | 0).toString(16);
    return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, function() {
        return (Math.random() * 16 | 0).toString(16);
    }).toLowerCase();
};

const openTaskPanel = (props, data, options) => {
    return new Promise((resolve,reject)=>{
        if((data.task_id || data._id) && options){
            props.setTaskDataLoad(false);
            props.setButonActive(false);
            props.setSaveTaskPrompt(false);
            props.setPopup({ type: 'relatedAssigneePopup', data: false });
            props.setPopup({ type: 'relatedKeywordPopup', data: false });
            props.setPopup({ type: 'filterKeywordPopup', data: false });
            props.setPopup({ type: 'dateRangePopup', data: false });
            props.setPopup({ type: 'taskObserverRelatedPopup', data: false });
            props.setPopup({ type: 'taskRoomsRelatedPopup', data: false });
            props.setPopup({ type: 'selectPopup', data: false });
            props.setPopup({ type: 'taskProjectListPopup', data: false });
            props.setPopup({ type: 'reportPopup', data: false });
            props.setPopup({ type: 'reportListPopup', data: false });
            if(options.autoload){
                props.set_Task_panel_option({
                    options,
                    data: data
                });
            }
            
            props.set_fileViewType('task_management_panel');
            props.set_create_task(true);
            props.setTaskTab('tasks');
            instance.post('/task/get_all', {
                _id: data.task_id ? data.task_id : data._id,
            },{
                headers: {
                    'Authorization': getToken()
                }
            }).then(function (response) {
                //console.log('openTaskPanel', data, response.data)
                if(!response.data.tasks[0].view_cost?.includes(props.logindata.user.id) && 
                !response.data.tasks[0].view_hour?.includes(props.logindata.user.id) && 
                !response.data.tasks[0].view_description?.includes(props.logindata.user.id) &&
                !response.data.tasks[0].view_checklist?.includes(props.logindata.user.id)
                ){
                    props.setAllTaskUnread(oldValues => {
                        return oldValues.filter(val => val !== data._id)
                    })
                }
                    
                if(response.data?.tasks && response.data.tasks.length === 1){
                    modifyMyMsg(response.data.tasks[0].discussion,function(){});
                }
                
                props.set_Task_panel_option({
                    options,
                    data: response.data.success && response.data.tasks.length === 1 ? response.data.tasks[0] : {...data,
                        participants: data.participants && data.participants.length ? data.participants : [],
                        task_title: data.task_title ? data.task_title : '',
                        conversation_id: data.conversation_id ? data.conversation_id: '' ,
                        conversation_name: data.conversation_name ? data.conversation_name : '',
                        msg_id: data.msg_id ? data.msg_id : '',
                        discussion: []
                    }});

                    // setTimeout(() => {
                        props.setTaskDataLoad(true);
                        // props.setButonActive(false);
                    // }, 1000);
                
                
                resolve(response.data);
            })
            .catch(function (error) {
                //console.log(841,error)
                handleError(error,reject);
            })
        }
        else if(data.msg_id && options){
            instance.post('/task/convert', {msg_id: data.msg_id},{
                headers: {
                    'Authorization': getToken()
                }
            }).then(function (response) {
                //console.log('openTaskPanel', data, response.data)
                props.set_Task_panel_option({
                    options,
                    data: {...data,
                        participants: data.participants && data.participants.length ? data.participants : [],
                        task_title: data.task_title ? data.task_title : '',
                        conversation_id: data.conversation_id ? data.conversation_id: '' ,
                        conversation_name: data.conversation_name ? data.conversation_name : '',
                        msg_id: data.msg_id ? data.msg_id : '',
                        discussion: response.data.reply_msgs_old ? response.data.reply_msgs_old: []
                    }});
                props.set_fileViewType('task_management_panel');
                // props.set_conversation_gallery(false);
                props.set_create_task(true);
                props.setTaskTab('tasks');
                resolve();
            })
            .catch(function (error) {
                //console.log(841,error)
                handleError(error,reject);
            })
        }
        else{
            props.set_Task_panel_option({
                options,
                data: {...data,
                    participants: data.participants && data.participants.length ? data.participants : [],
                    task_title: data.task_title ? data.task_title : '',
                    conversation_id: data.conversation_id ? data.conversation_id: '' ,
                    conversation_name: data.conversation_name ? data.conversation_name : '',
                    msg_id: data.msg_id ? data.msg_id : '',
                    discussion: [],
                    temp_id: mongoObjectId()
                }});
            props.set_fileViewType('task_management_panel');
            // props.set_conversation_gallery(false);
            props.set_create_task(true);
            props.setTaskTab('tasks');
        }
    });
}

const read_all_unread_task =()=>{
    return new Promise((resolve, reject) => {    
        instance.post('/task/read_all_unread', {
            headers: {'Authorization': getToken()}
        })
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(1107,error.response)
            reject({type:'error',...error});
        })
    })
}

const meetingUrlClipboard = (event) => {
        // 
        event.target.select()
        navigator.clipboard.writeText(event.target.value);
        toast.success('Already Copied', {duration: 4000});
}
const open_meeting_window = () => {
    
    window.open(document.querySelector('.short_id_input').value,'_blank');
    // openCallWindow(conversation_id)
    
}    
const getUserActiveDeviceList =()=>{
    return new Promise((resolve, reject) => {
        //console.log(1066)        
        instance.get('/home/active_device_list', {
            headers: {'Authorization': getToken()}
        })
        .then(function (response) {            
            //console.log(1071,response.data);
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(157,error.response)
            reject({type:'error',...error});
        })
    })
}
const removeOtherDevice =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(1066, data)        
        instance.post('/home/remove_other_device', data, {
            headers: {'Authorization': getToken()}
        })
        .then(function (response) {            
            //console.log(1087,response.data);
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(1091,error.response)
            reject({type:'error',...error});
        })
    })
}
const update_my_sso =(data)=>{
    return new Promise((resolve, reject) => {
        //console.log(1098, data)        
        instance.post('/users/update_my_sso', data, {
            headers: {'Authorization': getToken()}
        })
        .then(function (response) {            
            //console.log(1103,response.data);
            resolve(response.data);
        })
        .catch(function (error) {
            //console.log(1107,error.response)
            reject({type:'error',...error});
        })
    })
}
export {
    DeleteMsg,
    RemoveThisLine,
    ForwardMsg,
    EditMsg,
    AddReactionAPI,
    getReactionUser,
    flagUnplagAPI,
    getCreateGroupData,
    CreateConversation,
    pinUnpinAPI,
    getTagAPI,
    CreatePrivateTagAPI,
    AssignTagIntoFileAPI,
    FilterMessageAPI,
    ClearMsgAPI,
    DeleteRoomAPI,
    UpdateRoomAPI,
    getThreadConversationAPI,
    updateProfileAPI,
    updateUserAPI,
    SetNewPasswordAPI,
    getFilesSummaryAPI,
    getPagiFileAPI,
    ForwardFilesAPI,
    LeaveRoomAPI,
    clearNotificationAPI,
    convertTaskAPI,
    checkAPIserver,
    getCallingUrl,
    setCallingUrl,
    FilterMessageOLDAPI,
    meetingUrlClipboard,
    open_meeting_window,
    createLinkAPI,
    file_share_link_removeAPI,
    restoreFile,
    AssignTagIntoMsgAPI,
    updateSecretUsers,
    removeProfileImageAPI,
    archiveConversation,
    closeConversation,
    archiveCount,
    resendEmailAPI,
    getUserTeamData,
    openTaskPanel,
    mongoObjectId,
    otherTagDetails,
    getUserActiveDeviceList,
    removeOtherDevice,
    update_my_sso,
    read_all_unread_task
}
